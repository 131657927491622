// File: src\components\swap\LeftPages.tsx

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDarkModeManager } from '../../state/user/hooks';
import Logo from '../../assets/svg/light_header_logo.png';
import LogoDark from '../../assets/svg/dark_header_logo.png';
import { useActiveWeb3React } from '../../hooks';
import { Skeleton } from '@material-ui/lab';
import ReactCodeInput from 'react-code-input';
import { ButtonPrimary } from 'components/Button';
import { ethers } from 'ethers';

const AsFooter = styled.div`
  padding: 12px;
  margin-top: 4em;
`;

const CxPadBox = styled.div`
  background: ${({ theme }) => theme.bg1};
  border-radius: 15px;
  padding: 24px 30px 30px 30px;
  max-width: 400px;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        padding: 24px 10px 30px 10px;
    `};
`;

const TopCntnt = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 15px;
`;

const LeftBox = styled.div`
  display: flex;
  align-items: center;
`;

const RightBox = styled.div``;

const ButButton = styled.button`
  padding: 5px 24px;
  color: white;
  background-color: ${({ theme }) => theme.primary1};
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: 0;
  border: 0;
  margin: 0;
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  border-radius: 4px;
`;

const BottomContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AirdropContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  padding: 0px 10px 10px 10px;

  p {
    font-size: 0.7rem;
    text-align: center;
    margin-bottom: 8px;
  }
`;

const AirdropTitle = styled.h3`
  color: ${({ theme }) => theme.text1};
  margin-bottom: 0px;
  margin-top: 10px;
  text-align: center;
`;

const StyledAirdropInput = styled.div`
  margin-bottom: 38px;
  margin-top: 38px;
`;

const AirdropButton = styled(ButtonPrimary)`
  width: 100%;
  margin-top: 10px;
  background-color: ${({ theme }) => theme.primary1};
  color: ${({ theme }) => theme.text1};
  &:hover {
    background-color: ${({ theme }) => theme.primary2};
  }
`;

const InvitationLink = styled.a`
  color: ${({ theme }) => theme.text3};
  font-size: 12px;
  text-decoration: none;
  margin-top: 10px;
  &:hover {
    text-decoration: underline;
  }
`;

// USER DATA CSS

const UserData = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px 10px 0px 10px;
`;

const SeasonTitle = styled.h2`
  color: ${({ theme }) => theme.text1};
  font-size: 14px;
  text-align: center;
  margin-bottom: 5px;
`;

const TotalPoints = styled.h1`
  color: ${({ theme }) => theme.text1};
  font-size: 36px;
  text-align: center;
  margin: 0;
`;

const PointsSubtitle = styled.p`
  color: ${({ theme }) => theme.primary1};
  font-size: 12px;
  text-align: center;
  margin-top: 5px;
`;

const PointsTable = styled.div`
  margin-top: 20px;
`;

const PointsRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const PointsLabel = styled.span`
  color: ${({ theme }) => theme.text3};
  font-size: 14px;
`;

const PointsValue = styled.span`
  color: ${({ theme }) => theme.text1};
  font-size: 14px;
  font-weight: bold;
`;

const InviteMessage = styled.p`
  color: ${({ theme }) => theme.text3};
  font-size: 12px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const InviteCodeBox = styled.div`
  background-color: ${({ theme }) => theme.bg2};
  border-radius: 8px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const InviteCodeLabel = styled.span`
  color: ${({ theme }) => theme.text3};
  font-size: 14px;
`;

const InviteCode = styled.span`
  color: ${({ theme }) => theme.text1};
  font-size: 14px;
  font-weight: bold;
`;

const TotalReferred = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.text3};
  font-size: 14px;
`;

export default function LeftPages() {
  const [darkMode] = useDarkModeManager();
  // const [tvl, setTVL] = useState(0);
  const { account, library } = useActiveWeb3React();
  const [userDetails, setUserDetails] = useState({
    totalPoints: 0,
    holdingPoints: 0,
    swapPoints: 0,
    contractPoints: 0,
    referralPoints: 0,
    invitationCode: '',
    referrals: [],
  });
  const [loading, setLoading] = useState(false);
  const [airdropCode, setAirdropCode] = useState('');
  const [isRegistered, setIsRegistered] = useState(false);
  const [airdropCodeValid, setAirdropCodeValid] = useState(true);

  const LEADERBOARD_API_URL =
    process.env.REACT_APP_LEADERBOARD_API_URL || 'https://asteroneo-leaderboard-service-production.up.railway.app';

  // useEffect(() => {
  //   const getData = async () => {
  //     fetch('https://api.llama.fi/updatedProtocol/1pulse')
  //       .then((res) => res.json())
  //       .then((json) => {
  //         setTVL(json.currentChainTvls.PulseChain);
  //       });
  //   };
  //   getData();
  // }, []);

  useEffect(() => {
    fetchUserDetails();
  }, [account, LEADERBOARD_API_URL]);

  const handleAirdropJoin = async () => {
    if (!account || !airdropCode || !library) return;
    try {
      // Create a message to sign
      const message = `
      By participating in the AsteroNEO airdrop, I agree to the following terms:
      
      1. I understand that participation in the airdrop does not guarantee any financial earnings or rewards.
      2. I confirm that I am not a citizen or resident of the United States.
      3. I agree to the general terms of service and acknowledge that the AsteroNEO team is not liable for any potential losses or damages.
      4. I understand that the airdrop is subject to change or cancellation at any time without prior notice.
      5. I acknowledge that I am participating in the airdrop at my own risk and discretion.

      Invitation Code: ${airdropCode}
      `;
      // Request signature from the user
      const signer = library.getSigner();
      const signature = await signer.signMessage(message);

      // Verify the signature
      const recoveredAddress = ethers.utils.verifyMessage(message, signature);

      if (recoveredAddress.toLowerCase() !== account.toLowerCase()) {
        console.error('Signature verification failed');
        return;
      }

      // Proceed with the API call

      const response = await fetch(`${LEADERBOARD_API_URL}/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          address: account,
          invitationCode: airdropCode.toUpperCase(),
          signature: signature,
          message: message,
        }),
      });

      if (response.ok) {
        setIsRegistered(true);
        await fetchUserDetails();
      } else {
        const errorData = await response.json();
        if (response.status === 400 && errorData.error === 'Invalid invitation code') {
          setAirdropCodeValid(false);
        }
        console.error('Failed to join airdrop');
      }
    } catch (error) {
      console.error('Error joining airdrop:', error);
    }
  };

  // Move fetchUserDetails outside of useEffect and make it reusable
  const fetchUserDetails = async () => {
    if (!account) return;
    setLoading(true);
    try {
      const response = await fetch(`${LEADERBOARD_API_URL}/user/${account}`);
      if (response.ok) {
        const data = await response.json();
        setUserDetails({
          totalPoints: data.totalPoints,
          holdingPoints: data.holdingPoints,
          swapPoints: data.swapPoints,
          contractPoints: data.contractPoints,
          referralPoints: data.referralPoints,
          invitationCode: data.invitationCode,
          referrals: data.referrals,
        });
        setIsRegistered(true);
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
    } finally {
      setLoading(false);
    }
  };

  const renderStatValue = (value: number | undefined, decimals: number = 2, prefix: string = '') => {
    if (loading) {
      return (
        <Skeleton
          variant="text"
          width={80}
          height={20}
          animation="wave"
          style={{
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            display: 'inline-block',
            verticalAlign: 'top',
          }}
        />
      );
    }
    return account && value !== undefined ? `${prefix}${value.toFixed(decimals)}` : `${prefix}0`;
  };

  return (
    <>
      <AsFooter className="left_section">
        <CxPadBox className="left_section_zkdefi">
          <TopCntnt className="brd_box">
            <LeftBox>
              <img width={'65px'} src={darkMode ? LogoDark : Logo} alt="logo" />
            </LeftBox>
            <RightBox>
              <a
                href="https://asteroneo.medium.com/1342ccbe1904"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none' }}
              >
                <ButButton className="buy_unw_btn">Airdrop Details</ButButton>
              </a>
            </RightBox>
          </TopCntnt>
          <BottomContent>
            {!account || !isRegistered ? (
              <AirdropContainer>
                <AirdropTitle>ASTERONEO MAINNET AIRDROP 🚀</AirdropTitle>
                <p>Enter your invitation code to start earning points and claim the airdrop</p>
                <StyledAirdropInput>
                  <ReactCodeInput
                    type="text"
                    fields={5}
                    value={airdropCode}
                    onChange={(value) => {
                      setAirdropCode(value);
                      if (!airdropCodeValid) {
                        setAirdropCodeValid(true);
                      }
                    }}
                    inputMode="numeric"
                    name="airdrop-code"
                    isValid={airdropCodeValid}
                    inputStyle={{
                      width: '50px',
                      height: '50px',
                      fontSize: '20px',
                      backgroundColor: 'transparent',
                      border: `1px solid ${darkMode ? '#ffffff30' : '#00000030'}`,
                      color: darkMode ? 'white' : 'black',
                      textAlign: 'center',
                      textTransform: 'uppercase',
                    }}
                    inputStyleInvalid={{
                      width: '50px',
                      height: '50px',
                      fontSize: '20px',
                      color: darkMode ? 'white' : 'black',
                      backgroundColor: 'transparent',
                      border: '1px solid red',
                      textAlign: 'center',
                      textTransform: 'uppercase',
                    }}
                  />
                </StyledAirdropInput>
                <AirdropButton onClick={handleAirdropJoin} disabled={!airdropCode}>
                  Join Airdrop
                </AirdropButton>
                <InvitationLink href="https://t.me/AsteroNEO" target="_blank" rel="noopener noreferrer">
                  Get Invitation Code
                </InvitationLink>
              </AirdropContainer>
            ) : (
              <>
                <UserData>
                  <SeasonTitle>SEASON 1</SeasonTitle>
                  <TotalPoints>{renderStatValue(userDetails.totalPoints, 2)} 🌟</TotalPoints>
                  <a
                    href="https://leaderboard.asteroneo.com/"
                    target="_blank"
                    style={{
                      textDecoration: 'none',
                    }}
                  >
                    <PointsSubtitle>Check Leaderboard</PointsSubtitle>
                  </a>
                  <PointsTable>
                    <PointsRow>
                      <PointsLabel>Holding Points (4X)</PointsLabel>
                      <PointsValue>{renderStatValue(userDetails.holdingPoints, 2)} Points</PointsValue>
                    </PointsRow>
                    <PointsRow>
                      <PointsLabel>Activity Points (4X)</PointsLabel>
                      <PointsValue>
                        {renderStatValue(userDetails.swapPoints + userDetails.contractPoints, 2)} Points
                      </PointsValue>
                    </PointsRow>
                    <PointsRow>
                      <PointsLabel>Referral Points (1X)</PointsLabel>
                      <PointsValue>{renderStatValue(userDetails.referralPoints, 2)} Points</PointsValue>
                    </PointsRow>
                  </PointsTable>
                  <InviteMessage>Invite users and earn 10% of their points lifetime</InviteMessage>
                  <InviteCodeBox>
                    <InviteCodeLabel>Personal Invite Code:</InviteCodeLabel>
                    <InviteCode>
                      {loading ? (
                        <Skeleton
                          variant="text"
                          width={60}
                          height={20}
                          animation="wave"
                          style={{
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                            display: 'inline-block',
                            verticalAlign: 'top',
                          }}
                        />
                      ) : (
                        userDetails.invitationCode
                      )}
                    </InviteCode>
                  </InviteCodeBox>
                  <TotalReferred>
                    <span>Total Referred</span>
                    <span>
                      {loading ? (
                        <Skeleton
                          variant="text"
                          width={40}
                          height={20}
                          animation="wave"
                          style={{
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                            display: 'inline-block',
                            verticalAlign: 'top',
                          }}
                        />
                      ) : (
                        `${userDetails.referrals.length} Users`
                      )}
                    </span>
                  </TotalReferred>
                </UserData>
              </>
            )}
          </BottomContent>
        </CxPadBox>
      </AsFooter>
    </>
  );
}
